/*
 * @version: 1.0
 * @Date: 2022-01-17 10:12:36
 * @LastEditTime: 2022-02-11 11:12:50
 * @FilePath: \yc_otc_coupon_h5\src\store\index.js
 */
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { common } from "./modules";

export default new Vuex.Store({
  modules: {
    common: common
  }
});
