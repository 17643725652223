import Vue from "vue";

import VueRouter from "vue-router";

Vue.use(VueRouter);

let routes = [
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "../views/alterNative/login/index.vue"
      ),
    meta: {
      title: "登录"
    }
  },
  {
    path: "/device",
    name: "Device",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "../views/alterNative/device/index.vue"
      ),
    meta: {
      title: "设备管理"
    }
  },
  {
    path: "/addDevice",
    name: "AddDevice",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "../views/alterNative/device/addDevice.vue"
      ),
    meta: {
      title: "添加设备"
    }
  },
  {
    path: "/user",
    name: "User",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "../views/alterNative/user/index.vue"
      ),
    meta: {
      title: "用户管理"
    }
  },
  {
    path: "/doctor",
    name: "Doctor",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "../views/alterNative/doctor/index.vue"
      ),
    meta: {
      title: "医生管理"
    }
  }
];

const router = new VueRouter({
  routes
});

export default router;
