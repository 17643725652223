/*
 * @version: 1.0
 * @Date: 2022-01-17 15:47:05
 * @LastEditTime: 2022-02-15 08:39:07
 * @FilePath: \yc_otc_coupon_h5\src\utils\disableScaling.js
 */
window.onload = function () {
  // 阻止双击放大
  var lastTouchEnd = 0;
  document.addEventListener("touchstart", function (event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  });
  document.addEventListener(
    "touchend",
    function (event) {
      var now = new Date().getTime();
      if (now - lastTouchEnd <= 300) {
        event.preventDefault();
      }
      lastTouchEnd = now;
    },
    false
  );

  // 阻止双指放大
  document.addEventListener("gesturestart", function (event) {
    event.preventDefault();
  });
};
