import HttpRequest from "@/http/request";
function getUserId() {
  let baseInfo = JSON.parse(sessionStorage.getItem("baseInfo"));
  baseInfo = baseInfo || {};
  return baseInfo.id;
}
export default {
  //查看设备列表
  getDeviceList(data) {
    data.manageId = getUserId();
    return HttpRequest.postForm("H5Mange/equipmentInfo", data);
  },
  //设备解绑
  unBindEquipment(data) {
    data.manageId = getUserId();
    return HttpRequest.postForm("H5Mange/unBindEquipment", data);
  },
  //添加绑定设备
  addEquipment(data) {
    data.manageId = getUserId();
    return HttpRequest.postForm("H5Mange/bindEquipment", data);
  },
  //注销用户
  userLogout(data) {
    data.manageId = getUserId();
    return HttpRequest.postForm("H5Mange//userLogout", data);
  },
  //获取用户列表
  getUserList(data) {
    data.manageId = getUserId();
    return HttpRequest.postForm("H5Mange/userInfo", data);
  },
  //查询机构列表
  selectDeanInfo(data) {
    data.manageId = getUserId();
    return HttpRequest.postForm("H5Mange/selectDeanInfo", data);
  }
};
