/*
 * @version: 1.0
 * @Date: 2022-01-20 10:59:09
 * @LastEditTime: 2022-02-10 09:33:42
 * @FilePath: \vue2-demo\src\global\register-vant.js
 */
import Vue from "vue";
import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);
