/*
 * @version: 1.0
 * @Date: 2022-01-25 10:51:56
 * @LastEditTime: 2022-02-10 09:33:47
 * @FilePath: \vue2-demo\src\global\register-api.js
 */

import Vue from "vue";
import { api } from "@/http/api";

Vue.prototype.$api = api;
