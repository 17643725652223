/*
 * @Author: zzl 2631154063@qq.com
 * @Date: 2022-12-28 12:37:49
 * @LastEditors: zzl 2631154063@qq.com
 * @LastEditTime: 2022-12-29 09:31:18
 * @FilePath: \hello-world\src\http\api\login.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import HttpRequest from "@/http/request";

export default {
  // 登录接口
  login(data) {
    return HttpRequest.postForm("H5Mange/login", data, {
      isToken: false
    });
  }
};
