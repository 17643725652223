import login from "./login";
// import weChat from "./weChat";
import user from "./user";
// import address from "./address";
// import common from "./common";

const api = {
  login,
  user
  // weChat,
  // address,
  // common
};

export { api };
