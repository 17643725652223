import axios from "axios";
import qs from "qs";
// import store from "@/store";
import store from "../store/index.js";
import { baseURL } from "@/http/setBaseURL";

class HttpRequest {
  constructor() {
    this.timeout = 5000;
    this.baseURL = baseURL;
    // this.baseURL = "/api";
    // this.baseURL = "https://mapi.yicheng120.com/cc-api/coupon"; //正式环境
    // this.baseURL = "https://mapi.yicheng120.com/cc-api-test/coupon"; //测试环境
  }
  request(options) {
    const instance = axios.create();

    // console.log(a);
    // 添加请求拦截器
    instance.interceptors.request.use(
      // 在发送请求之前做些什么
      function (config) {
        // 设置请求头content-type
        config.headers.setContentType(config.header["content-type"]);
        // config.headers.setContentType("application/json");

        const baseInfo = JSON.parse(sessionStorage.getItem("baseInfo"));
        // const token = 8888;
        const token =
          store.state.common.baseInfo.token || (baseInfo && baseInfo.token);
        if (config.other.isToken && token) {
          // 过滤taken
          config.headers["Authorization"] = token;
        }

        // config.headers.ClientType = "APP";
        // console.log(config);
        // const CancelToken = axios.CancelToken;
        // config.cancelToken = new CancelToken(function executor(c) {
        // executor 函数接收一个 cancel 函数作为参数
        // console.log(store);
        //   store.commit(Type.SET_Token_List, c);
        // });
        return config;
      },
      function (error) {
        // 对请求错误做些什么
        return Promise.reject(error);
      }
    );

    // 添加响应拦截器
    instance.interceptors.response.use(
      function (response) {
        if (response.status == 200) {
          if (
            response.data &&
            (response.data.code == 0 || response.data.code == 200)
          ) {
            return response.data;
          } else {
            return Promise.reject(response.data);
          }
        } else {
          return Promise.reject(response.data);
        }
        // 对响应数据做点什么
      },
      function (error) {
        // 对响应错误做点什么
        return Promise.reject(error);
      }
    );

    let config = {
      baseURL: this.baseURL,
      timeout: this.timeout,
      ...options
    };

    return instance(config);
  }
  postForm(url, data, other = { isToken: true }) {
    return this.request({
      url,
      method: "post",
      header: { "content-type": "application/x-www-form-urlencoded" },
      data: qs.stringify(data),
      other
    });
  }
  postFile(url, data, other = { isToken: true }) {
    return this.request({
      url,
      method: "post",
      header: { "content-type": "multipart/form-data" },
      data,
      other
    });
  }
  post(url, data, other = { isToken: true }) {
    return this.request({
      url,
      method: "post",
      header: { "content-type": "application/json" },
      data,
      other
    });
  }
  get(url, data, other = { isToken: true }) {
    return this.request({
      url,
      method: "get",
      header: { "content-type": "application/x-www-form-urlencoded" },
      params: data,
      other
    });
  }
}

export default new HttpRequest();
