/*
 * @version: 1.0
 * @Date: 2021-10-18 12:48:34
 * @LastEditTime: 2023-01-04 12:42:23
 * @FilePath: \yc_otc_coupon_h5\src\http\setBaseURL.js
 */
let baseURL = "";
const { VUE_APP_ENV_VARIABLE } = process.env;
if (VUE_APP_ENV_VARIABLE == "development") {
  //本地环境
  baseURL = "/api";
} else if (VUE_APP_ENV_VARIABLE == "test") {
  baseURL = "https://mapi.yicheng120.com/yinchuan_home";
} else if (VUE_APP_ENV_VARIABLE == "production") {
  baseURL = "https://mapi.yicheng120.com/yinchuan_home";
}

export { baseURL };
