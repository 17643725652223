/*
 * @version: 1.0
 * @Date: 2022-01-25 14:34:59
 * @LastEditTime: 2022-03-16 09:55:08
 * @FilePath: \yc_otc_coupon_h5\src\store\modules\common.js
 */
const state = {
  // 账号基础信息
  baseInfo: {
    token: "",
    id: "", //患者ID
    userAccount: "", //用户账号手机号
    accountType: "", //账号基本类型
    isPlusMember: "" //会员状态 0：普通用户 1：plus会员
  }
};

const mutations = {
  BASE_INFO: (state, baseInfo) => {
    state.baseInfo = baseInfo;
    sessionStorage.setItem("baseInfo", JSON.stringify(state.baseInfo));
  }
};

const actions = {
  baseInfo: ({ commit }, baseInfo) => {
    commit("BASE_INFO", baseInfo);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
