/*
 * @version: 1.0
 * @Date: 2022-01-25 12:23:25
 * @LastEditTime: 2023-01-04 14:46:03
 * @FilePath: \yc_otc_coupon_h5\src\global\permission.js
 */
import router from "@/router";

// 白名单
const whiteList = ["/login", "/device", "/doctor", "/user", "/addDevice"];

// 需要清空缓存的路由
const clearList = ["/login"];

router.beforeEach((to, from, next) => {
  // 获取token路由守卫必须写在里边，否则第一次进入不刷新状态下token为null
  const baseInfo = JSON.parse(sessionStorage.getItem("baseInfo"));

  // token
  const isAuthenticated = baseInfo ? baseInfo.token : null;

  // 清空缓存信息
  if (clearList.indexOf(to.path) >= 0) {
    let sessionKeys = Object.keys(sessionStorage);
    sessionKeys.forEach((key) => {
      // 需要过滤掉推广码的缓存
      if (key != "promotionCode") sessionStorage.removeItem(key);
    });
  }

  // 判断当前是否存在token
  if (isAuthenticated) {
    next();
  } else {
    // 白名单直接过滤
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      // 如果设置指定重定向页面跳转,反之跳转至登录页面
      if (to.meta && to.meta.errRedirect) {
        next({ path: to.meta.errRedirect });
      } else {
        next({ path: "/login" });
      }
    }
  }
});
